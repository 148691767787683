body,
#root,
.App {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}
body {
  height: 100vh;
  justify-content: flex-start;
}
#root,
.App {
  align-items: center;
  text-align: center;
  height: 100%;
}

.content {
  max-width: 1024px;
  width: calc(100% - 40px);
  padding: 10px 20px;
  margin-top: 56px;
  height: 100%;
}

.content__loading {
  min-height: calc(100vh - 76px);
  justify-content: center;
  align-items: center;
}

.content__header {
  display: flex;
  width: 100%;
  height: 30px;
  justify-content: space-between;
}

.content__header h1 {
  margin: 0;
  font-size: 1.5em;
}

button:focus {
  outline: none;
}

@media (max-width: 414px) {
  .content__header h1 {
    font-size: 1.2em;
  }
  .content__header span {
    font-size: 0.8em;
  }
}
